.reports-ctn {
  .MuiPaper-root {
    box-shadow: none !important;
    .MuiTableBody-root .MuiTableCell-body {
      padding: 4px !important;
    }
  }
  .myb-bets-div {
    .myb-btn-next,
    .myb-btn-prev {
      margin-left: 10px !important;
      color: #fff !important;
    }
  }
  .MuiPaper-root {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    border: 1px solid var(--filter-border);
    background-color: var(--ion-background) !important;
    .MuiTableHead-root .MuiTableRow-head {
      border-left: 20px solid var(--report-table-header) !important;
      border-right: 10px solid var(--report-table-header) !important;
      color: var(--sub-header-text-color) !important;
      background: var(--report-table-header) !important;
      .MuiTableCell-head {
        font-size: 16px !important;
        font-family: var(--font-family);
        background: var(--report-table-header) !important;
        color: var(--sub-header-text-color) !important;
        border-bottom: 1px solid var(--report-table-border-color) !important;
      }
    }
    .MuiTableBody-root {
      br {
        content: '';
        display: block;
        font-size: 100%;
        height: 0.2em;
      }
      .lay-bg,
      .back-bg {
        background: var(--back-odd-background) !important;
        color: var(--text-primary) !important;
        &.lay-bg {
          background: var(--lay-odd-background) !important;
        }
        .profit-th {
          border-left: 7px solid #279b4e !important;
        }
        .loss-th {
          border-left: 7px solid #c6556b !important;
        }
        .row-th {
          border-left: 7px solid #b0b0b0 !important;
        }

        .MuiTableCell-body {
          font-size: 14px;
          border-spacing: 30px;
          font-weight: 500;
          font-family: var(--font-family);
          color: #000 !important;
          padding: 6px !important;
          align-items: center;
          .odds-ctn {
            display: flex;
            align-items: center;
            flex-direction: row;
          }
          &.profit {
            color: var(--ion-color-success) !important;
          }
          &.loss {
            color: var(--ion-color-danger) !important;
          }
        }
      }
      .loss-bg,
      .profit-bg {
        background-color: var(--profit-bg) !important;
        &.loss-bg {
          background-color: var(--loss-bg) !important;
        }
        color: var(--text-primary) !important;
        .profit-th {
          border-left: 7px solid #279b4e !important;
        }
        .loss-th {
          border-left: 7px solid #c6556b !important;
        }
        .row-th {
          border-left: 7px solid #b0b0b0 !important;
        }
        th {
          border-left: 10px solid #279b4e !important;
        }
        .MuiTableCell-body {
          font-size: 14px;
          border-spacing: 30px;
          font-weight: 500;
          font-family: var(--font-family);
          color: var(--text-primary);
          padding: 6px !important;
          &.profit {
            color: var(--ion-color-success) !important;
          }
          &.loss {
            color: var(--ion-color-danger) !important;
          }
        }
      }

      .row-bg {
        color: var(--text-primary) !important;
        .profit-th {
          border-left: 7px solid #279b4e !important;
        }
        .loss-th {
          border-left: 7px solid #c6556b !important;
        }
        .row-th {
          border-left: 7px solid #b0b0b0 !important;
        }
        .MuiTableCell-body {
          font-size: 14px;
          font-weight: 500;
          font-family: var(--font-family);
          color: var(--text-primary);
          padding: 6px !important;
          &.profit {
            color: var(--ion-color-success) !important;
          }
          &.loss {
            color: var(--ion-color-danger) !important;
          }
        }
      }

      .MuiTableRow-body {
        border: 3px solid var(--ion-background);
        font-size: 14px;

        .MuiTableCell-body {
          font-size: 14px;
          font-weight: 500;
          font-family: var(--font-family);
          color: var(--text-primary) !important;
        }
      }
    }
  }
  .no-data-row {
    background-color: var(--table-body-background);
    width: 100%;
    border-bottom: 0px !important;
    color: var(--reports-title-color);
    height: 40px;
    padding-top: 15px;
    text-align: center;
  }
}
.reports-ctn {
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: 1%;
  margin-right: 2% !important;
  .header-ctn {
    .img-page-title-row {
      display: flex;
      justify-content: left;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      padding: 4px;
      .title-image {
        height: 40px;
        width: 40px;
      }
      .title {
        margin-left: 10px;
        font-size: 25px;
        font-style: italic;
        font-weight: 700;
        line-height: 45px;
        letter-spacing: 0em;
        color: var(--text-theme);
        text-transform: uppercase;
        font-weight: bold;
        .prev-level-link {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .filters-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 10px;
    .select-filter {
      margin-left: 0.6rem;
      margin-right: 0.6rem;
      .select-filter-label {
        font-size: 16px;
        color: var(--label-color);
        font-weight: var(--font-weight);
      }
      .select-filter-control {
        margin-top: 0.1rem;
        height: 40px;
        background-color: var(--ion-background);
        color: var(--text-primary);
        border: 1px solid var(--filter-border);
        width: 100%;
      }
    }
    .date-filter {
      margin-left: 0.6rem;
      margin-right: 0.6rem;
      .date-filter-label {
        font-size: 16px;
        color: var(--label-color);
        font-weight: var(--font-weight);
      }
      .readonly {
        opacity: 0.6 !important;
      }
      .date-filter-control {
        margin-top: 0.1rem;
        height: 40px;
        background-color: var(--ion-background);
        color: var(--text-primary);
        border: 0px;
        width: 100%;
        .MuiInputBase-root {
          margin-top: 0.1rem;
          min-height: 40px;
          background-color: var(--ion-background);
          color: var(--text-primary);
          border: 1px solid var(--filter-border);
          min-width: 100%;
          border-radius: 0px;
          .MuiIconButton-root {
            filter: var(--date-icon);
          }
          height: 30px;
          width: 180px;
          .MuiInputBase-input,
          .MuiOutlinedInput-input {
            padding: 4px 16px;
          }
        }
      }
    }
  }

  .content-ctn {
    border-radius: 6px;
    // .tbl-ctn:not(.no-hov-style) {
    //   .MuiTableContainer-root {
    //     .MuiTable-root {
    //       .MuiTableBody-root {

    //       }
    //     }
    //   }
    // }

    // .table-ctn,
    // .tbl-ctn {
    //   .MuiTableContainer-root {
    //     background-color: inherit;
    //     .MuiTable-root {
    //       .MuiTableHead-root {
    //         border: none;
    //       }
    //       .MuiTableBody-root {
    //         .MuiTableBody-root {
    //           .MuiTableRow-body {
    //             border: 1px solid var(--table-head-bg);
    //             font-size: 14px;
    //           }
    //           .back-bet {
    //             background: var(--back-odd-background) !important;
    //             .MuiTableCell-body,
    //             .MuiSvgIcon-root {
    //               color: var(--text-common-dark) !important;
    //             }
    //           }

    //           .lay-bet {
    //             background: var(--lay-odd-background) !important;
    //             .MuiTableCell-body,
    //             .MuiSvgIcon-root {
    //               color: var(--text-common-dark) !important;
    //             }
    //           }
    //         }
    //         .back-bet {
    //           background: var(--back-odd-background) !important;
    //           .MuiTableCell-body,
    //           .MuiSvgIcon-root {
    //             color: var(--text-common-dark) !important;
    //           }
    //         }
    //         .lay-bet {
    //           background: var(--lay-odd-background) !important;
    //           color: var(--text-common-dark) !important;
    //           .MuiTableCell-body,
    //           .MuiSvgIcon-root {
    //             color: var(--text-common-dark) !important;
    //           }
    //         }
    //         .MuiTableRow-root {
    //           border-bottom: 1px solid rgba(53, 48, 49, 0.1);
    //           .MuiTableCell-body,
    //           .MuiSvgIcon-root {
    //             color: var(--ion-text-light);
    //           }
    //         }
    //         .MuiTableRow-root.back-odd-row {
    //           background: var(--back-odd-background) !important;
    //         }
    //         .MuiTableRow-root.lay-odd-row {
    //           background: var(--lay-odd-background) !important;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  // .MuiPaper-root .MuiTableBody-root .MuiTableCell-body {
  //   color: var(--input-text-color);

  //   &.profit {
  //     color: var(--ion-color-success) !important;
  //   }
  //   &.loss {
  //     color: var(--ion-color-danger) !important;
  //   }
  // }
}

@media only screen and (max-width: 720px) {
  .admin-module-ctn {
    padding-bottom: 25px;
  }
  .reports-ctn {
    margin-left: 0px !important;
    margin-right: 18px !important;
    .header-ctn,
    .content-ctn {
      padding: 0 8px;
      .points-type-toggle {
        height: 32px;
        border-radius: 4px;
        .MuiBottomNavigationAction-root {
          font-size: 14px;
          line-height: 17px;
          min-width: 70px;
        }
      }
    }
    .filters-row {
      display: flex;
      flex-wrap: wrap;
      .select-filter {
        margin: 0.3rem;
        flex: 0 0 47%;
        .select-filter-label {
          font-size: 12px;
        }
        .select-filter-control {
          margin-top: 0.1rem;
          width: 100%;
          font-size: 10px;
        }
      }
      .date-filter {
        margin: 0.3rem;
        flex: 0 0 47%;
        .date-filter-label {
          font-size: 12px;
          margin-left: 0.3rem;
        }
        .date-filter-control {
          margin-top: 0%;
          .MuiInputBase-root {
            height: 30px;
            width: 100%;
            .MuiInputBase-input,
            .MuiOutlinedInput-input {
              font-size: 10px;
              padding: 4px 16px;
            }
          }
        }
      }
      .readonly {
        background: rgba(207, 207, 207, 0.5);
      }
    }
    .header-ctn {
      padding-left: 0px;
      padding-right: 0px;
      .img-page-title-row {
        margin-top: 0.3rem;
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        display: flex;
        align-items: center;
        .title-image {
          height: 30px;
          width: 30px;
        }
        .title {
          font-size: 16px;
          line-height: 22px;
          margin-left: 0.3rem;
        }
      }
    }

    // .tbl-ctn {
    //   .MuiTableContainer-root {
    //     background-color: inherit;
    //     .MuiTable-root {
    //       .MuiTableHead-root {
    //         border: none;
    //       }
    //     }
    //   }
    // }
    // .content-ctn {
    //   .table-ctn {
    //     .MuiTableCell-root {
    //       padding: 8px;
    //     }
    //   }
    // }

    // .txt-info {
    //   font-weight: bold;
    //   font-size: 13px;
    // }
  }
  .text-ellipsis {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.date-filter-control {
  .MuiInputBase-root {
    border: none;
    .MuiInputBase-input,
    .MuiOutlinedInput-input {
      color: var(--text-primary) !important;
      border: none !important;
    }
  }
}
