.centered {
  text-align: center;
}

.spinner.loading {
  text-align: center;
}

.loading-text {
  width: 90px;
  position: absolute;
  top: calc(50% - 34px);
  left: calc(50% - 46px);
  text-align: center;
  color: #fff;
  font-size: 32px;
}

.spinner.loading:before {
  content: '';
  height: 60px;
  width: 60px;
  margin: -15px auto auto -15px;
  position: absolute;
  top: calc(50% - 34px);
  left: calc(50% - 20px);
  border-width: 4px;
  border-style: solid;
  border-color: #2180c0 #fff #fff;
  border-radius: 100%;
  animation: rotation 0.7s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.main-header-ctn {
  width: 100%;
  padding: 0 18px;
  background: var(--ion-header-background);
  height: 52px;

  .header-row {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  .h-btns {
    font-size: 18px;
    font-weight: 600;
    color: var(--btn-bg);
    letter-spacing: 0.02em;
  }

  .icon-title {
    cursor: pointer;
    ion-img {
      width: var(--logo-xl-width);
      height: var(--logo-xl-height);
    }
  }

  .live-clock {
    color: var(--ion-header-text-color);
   
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 18px;
    color: #ffffff;
    .date {
      opacity: 0.4;
    }
  }
}

@media screen and (max-width: 720px) {
  .main-header-ctn {
    padding: 0 12px;

    .MuiToolbar-root {
      padding: 0;
    }
  }
}
