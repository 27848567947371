.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialog-paper {
      .MuiDialogTitle-root {
        color: var(--ion-text-white);
        text-align: center;
        height: auto;
        font-family: var(--font-family);
        .MuiTypography-root {
          color: var(--ion-text-white);
          font-size: 13px;
          font-family: var(--font-family);
        }
      }
      .MuiIconButton-root {
        position: absolute;
        top: 8px;
        right: 8px;
        opacity: 1;
      }

      .light-bg-title {
        text-align: center;
        height: 80px;
        .MuiTypography-root {
          color: var(--ion-text-white);
        }
      }

      .MuiDialogContent-root {
        padding: 0px 28px !important;
        color: var(--ion-text-white);
        .MuiButton-containedPrimary {
          background: var(--active-tab);
          color: var(--ion-text-white);
          text-transform: none;
          font-weight: 600;
        }
        .MuiButton-containedPrimary:hover {
          background: var(--active-tab);
          text-transform: none;
          color: var(--ion-text-white);
        }
      }
    }
  }
}
