.MuiAccordion-root {
  // background: var(--ion-header-background) !important;
  border: 1px solid var(--border-color);
  .MuiAccordionSummary-root {
    font-style: normal;
    box-sizing: border-box;
    background: var(--sub-header-color) !important;
    color: var(--sub-header-text-color) !important;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    align-items: center;
    border-radius: 0px;
    padding: 12px 20px;
    margin-top: 0px;
    height: 40px !important;
    min-height: 40px !important;
    text-transform: uppercase;
    svg {
      fill: var(--sub-header-text-color) !important;
    }
    &.Mui-expanded {
      background: var(--active-tab) !important;
      color: var(--sub-header-text-color) !important;
      svg {
        fill: var(--sub-header-text-color) !important;
      }
    }
  }
  .MuiCollapse-wrapperInner,
  .MuiAccordionDetails-root {
    display: initial;
    padding: 0;
    background: var(--accordion-details-root-background) !important;
  }
}

// Accordians in modals
// Ex: Rules accoridan body
.MuiDialog-paper {
  .MuiAccordion-root {
    .MuiButtonBase-root {
      border-radius: 0;
    }

    .MuiAccordionDetails-root {
      font-size: 14px;
      padding-left: 10px;
      background: var(--ion-background);
      border-radius: 0;
    }
  }
}

@media only screen and (max-width: 720px) {
  .MuiAccordion-root {
    // Accordian header
    .MuiAccordionSummary-root {
      border-radius: 0;
      padding: 0;
      font-size: 14px;
      line-height: 17px;
      height: 36px !important;
      min-height: 36px !important;

      .MuiAccordionSummary-content {
        padding-left: 10px;
      }
    }
    // Accordian boday
    .MuiAccordionDetails-root {
      // padding: 8px 12px;
      padding: 0;
    }
  }
}
.MuiAccordion-rounded:last-child {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border: 0px !important;
}
.MuiAccordionSummary-content{
  color: var(--sub-header-text-color) !important;
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 600 !important;
}