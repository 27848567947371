.login-form-page {
  .title-row {
    display: flex;
    justify-content: center;

    .logo {
      margin-top: 30px;
      margin-bottom: var(--login-margin-btn);
      height: var(--login-img-height) !important;
    }
  }

  @media screen and (max-width: 720px) {
    display: flex;
    justify-content: center !important;
    width: 100vw;
  }

  .login-form-ctn {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 330px;

    span,
    div {
      color: var(--ion-text-light) !important;
    }

    .form-title {
      font-weight: bold;
      font-size: 22px;
    }

    .login-demologin-btns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 300px;
      .login-form-btn {
        width: 48%;
        height: 45px;
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        background-color: var(--active-tab);

        @media screen and (max-width: 720px) {
          min-width: 300px !important;
          max-width: 400px !important;
        }

        .MuiButton-label {
          color: var(--tab-text) !important;
        }
      }

      .login-form-btn-without-demologin {
        width: 100%;
        height: 45px;
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        background-color: var(--active-tab);

        @media screen and (max-width: 720px) {
          min-width: 300px !important;
          max-width: 400px !important;
        }

        .MuiButton-label {
          color: var(--tab-text) !important;
        }
      }
    }

    .back {
      max-width: 300px;
      text-align: center;
      color: var(--text2) !important;
      margin-top: 10px;

      @media screen and (max-width: 720px) {
        min-width: 300px !important;
        max-width: 400px !important;
      }

      .navlink {
        text-decoration: none;
        color: var(--text2) !important;
      }
    }

    .bottom-link {
      margin-top: auto;
      margin-bottom: 1rem;
      font-size: 14px;
      text-align: center;

      .register-link {
        color: var(--btn-bg);
        cursor: pointer;
      }
    }

    .form-title {
      margin-top: 1rem;
      color: #fff !important;
      text-align: center;
    }

    .MuiFormControl-root {
      background: var(--side-menu);
      border: 0px !important;
    }

    .usr-input,
    .pwd-input {
      margin-top: 0.7rem;
      display: flex;
      flex-direction: column;
      max-width: 300px;

      @media screen and (max-width: 720px) {
        min-width: 300px !important;
        max-width: 400px !important;
        margin-top: 15px;
      }

      border: 0px !important;

      .input-label {
        color: var(--ion-text-white);

        @media screen and (max-width: 720px) {
          margin-top: 10px;
        }
      }

      .MuiIconButton-root {
        padding: 0;
      }

      .MuiOutlinedInput-adornedEnd {
        background-color: var(--login-input-bg);
      }
    }

    .login-err-msg {
      color: var(--ion-text-white) !important;
    }
  }
}

@media (min-width: 1024px) {
  .login-form-ctn {
    min-height: 100vh;
  }
}

@media (max-width: 600px) {
  .login-form-ctn {
    display: flex;
    justify-content: center;
    min-height: 100%;
  }
}

@media (max-width: 720px) {
  .login-form-page {
    .login-form-ctn {
      .login-demologin-btns {
        display: flex;
        flex-direction: column;
        .login-form-btn { 
          margin-top: 10px;
        }
      }
    }
  }
}