.MuiPaper-root {
  .MuiDialog-paper {
    margin: 0px;
    background: var(--sub-header-color);
    color: var(--ion-text-white);
  }
}
.MuiDialog-paperScrollPaper{
  background: var(--sub-header-color) !important;
  color: var(--ion-text-white);
}