.MuiPaper-root {
  margin-top: 16px;
  box-shadow: none;
  border-color: none;
  // width: 100%;
  border-radius: 6px;
  .MuiTable-root {
    border: none;
    background-color: inherit;
  }
  .MuiTableHead-root {
    height: 31px;
    background-color: var(--table-head-bg);
    border: 0px !important;
    .MuiTableRow-head {
      background-color: var(--table-head-bg);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
      .MuiTableCell-head {
        color: var(--ion-text-white);
        border: 0px !important;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 106.7%;
        white-space: nowrap;
        padding: 2px 16px;

        .MuiIconButton-root {
          opacity: 1;
          .MuiTableSortLabel-root .MuiSvgIcon-root {
            color: #fff !important;
            opacity: 1 !important;
          }
        }
      }
    }
  }

  .MuiTableBody-root {
    background-color: var(--table-body-background);
    .MuiTableRow-body {
      background-color: var(--table-body-background);
    }
    .MuiTableCell-body {
      color: var(--ion-text-light) !important;
      border: 0px !important;
      cursor: default;
      white-space: nowrap;
      font-size: 14px;
      // background-color: var(--table-body-background);
    }
    .MuiSvgIcon-root {
      color: var(--ion-text-light);
    }

    .back-odd-row {
      background: var(--back-odd-background);
      color: #000 !important;
    }

    .lay-odd-row {
      background: var(--lay-odd-background);
      color: #000 !important;
    }

    .profit-bet {
      color: var(--ion-color-success) !important;
    }

    .loss-bet {
      color: var(--ion-color-lost) !important;
    }
  }
}

@media only screen and (max-width: 720px) {
  .MuiPaper-root {
    margin-top: 16px;
    .MuiTable-root {
      .MuiTableHead-root {
        .MuiSvgIcon-root {
          font-size: 20px;
        }
      }
      .MuiTableBody-root {
        .MuiTableCell-body {
          font-size: 12px;
          min-height: 42px;
          // width: 100%;
        }
        .MuiSvgIcon-root {
          font-size: 18px;
          margin-bottom: -2px;
        }
      }
    }
  }
  .MuiPaper-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
    font-size: 16px !important;
  }
}
