@import './table.scss';
@import './buttons.scss';
@import './select.scss';
@import './datepicker.scss';
@import './dialog.scss';
@import './popover.scss';
@import './accordion.scss';
@import './reports.scss';
@import './loadingCircle';
@import './search_filter.scss';
@import './header.scss';
@import './scrollbar.scss';
@import './oddsTable.scss';
@import '../fonts/jost.ttf';
@import '../fonts/jost.ttf';
@import '../fonts/jost-italic.ttf';

// @import url('https://fonts.googleapis.com/css2?family=Istok+Web&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@1,500&display=swap');

@font-face {
  font-family: 'jost';
  src: local('jost'), url('../fonts/jost.ttf') format('truetype');
}

@font-face {
  font-family: 'jost';
  src: local('jost'), url('../fonts/jost-italic.ttf') format('truetype');
}

.MuiFormHelperText-contained {
  margin: 0px !important;
  background: none !important;
}
.MuiFormHelperText-root {
  color: var(--ion-color-danger) !important;
}

.MuiInputBase-root .MuiFormControl-root,
.MuiOutlinedInput-root,
input {
  background: var(--input-background-color) !important;
  color: var(--input-text-color) !important;
  border-radius: 8px;
  border: 0px !important;
  font-family: var(--font-family) !important;
}
.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  font-family: var(--font-family) !important;
}
.MuiInputBase-root .Mui-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.text-white {
  color: #fff !important;
  margin-left: 5px;
}

.MuiInputBase-root {
  color: var(--input-text-color) !important;
  font-size: 14px;
  height: 48px;
  padding: 0;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 8px;
  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 0px;
    font-size: 16px;

    padding: 14px 16px;
  }
  .MuiSvgIcon-root {
    color: var(--input-text-color);
  }
}

.sc-ion-input-md-h input {
  border-radius: 8px !important;
}

.input-label {
  color: var(--ion-text-light);

  font-size: 14px;
  line-height: 19px;
  padding-bottom: 6px;
}

// Modal styles
.modal-title {
  .MuiTypography-root {
    font-size: 32px !important;
    color: #ffffff;
  }
}

.hand-pt {
  cursor: pointer;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--input-text-color) !important;
  -webkit-transition: background-color 9999s ease-out !important;
}

/* Material UI Tabs */
.MuiTab-textColorInherit.Mui-selected {
  border-bottom: 2px solid var(--btn-bg);
}
.MuiTab-root {
  text-transform: none;
}
.MuiTabs-indicator {
  display: none;
}

/* Punter reports styles */
.admin-module-ctn {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;
}

/* Recaptcha styles */
.recaptcha {
  display: none;
}

.sticky-col {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 5px;
}

.b-text {
  font-weight: 600;
}

.tiny-info-text {
  font-size: 12px;
}

.d-flex-column {
  display: flex !important;
  flex-direction: column;
}

.left-align {
  align-items: left !important;
}

.m-link {
  cursor: pointer !important;
}

.profit {
  color: var(--ion-color-success) !important;
  font-weight: bold;
}

.loss {
  color: var(--ion-color-danger) !important;
  font-weight: bold;
}

// Carousel styles
.carousel .slide {
  background: none !important;
}

.primary-b-text {
  font-weight: 600;
  // color: var(--btn-bg-tint);
}

@keyframes color-change {
  0% {
    color: red;
  }
  50% {
    color: var(--ion-color-success);
  }
  100% {
    color: var(--btn-bg);
  }
}

@keyframes flashing-text {
  0% {
    color: red;
    transform: scale(1.2);
  }
  25% {
    color: var(--ion-color-success);
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    color: red;
    transform: scale(1);
  }
  100% {
    color: var(--ion-color-success);
    transform: scale(1.2);
  }
}

@media screen and (max-width: 720px) {
  .web-view {
    display: none !important;
  }
}

@media (min-width: 721px) {
  .mob-view {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .tab-view {
    display: none !important;
  }

  .odds-web-view {
    display: none;
  }
}

@media (min-width: 1200px) {
  .lap-view {
    display: none !important;
  }

  .odds-mob-view {
    display: none;
  }
}

.Toastify__toast--success {
  background: var(--ion-toast--success) !important;
  color: var(--ion-text-white) !important;
}
.MuiBackdrop-root {
  background: rgba(22, 22, 22, 0.8) !important;
}
body {
  font-family: var(--font-family) !important;
}
div,
p,
span {
  font-family: var(--font-family) !important;
}
// .MuiTableCell-body{
//   padding: 0px !important;
// }
.input-wrapper {
  font-family: var(--font-family) !important;
}
.sc-ion-label-ios-h {
  font-family: var(--font-family) !important;
  font-weight: bold;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
