.events-table-ctn {
  // .banner-container {
  //   background: url('../../assets/images/sportsbook/banners/cricket-kohli.png');
  //   background-repeat: no-repeat;
  //   background-size: 100%;
  //   height: 200px;
  //   width: 100%;
  //   display: flex;
  //   align-items: center;
  //   border-radius: 6px;
  //   overflow: hidden;
  //   margin-bottom: 3px;
  //   cursor: default;
  //   .sport-image {
  //     height: 140px;
  //     margin-left: 5%;
  //     &.inplay-image {
  //       height: 80px;
  //     }
  //   }

  //   .banner-nav-link {
  //     color: #fff;
  //     text-decoration: none;
  //   }

  //   .eventType-name {
  //     font-style: normal;
  //     font-weight: normal;
  //     font-size: 82px;
  //     line-height: 98px;
  //     letter-spacing: 0.1em;
  //     color: #f8f8f8;
  //     margin-left: 12%;
  //     margin-right: 22%;
  //     text-transform: uppercase;
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //     .inplay-live-icon {
  //       height: 58px;
  //       width: 58px;
  //       margin-right: 21px;
  //       margin-top: -8px;
  //     }
  //   }
  // }
  .MuiPaper-root {
    background-color: var(--ion-background);
    border: 1px solid var(--table-border-color);
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    font-family: var(--font-family);
  }
  .no-border .MuiPaper-root {
    border: 0px !important;
  }
  // .MuiPaper-elevation1 {
  //   box-shadow: none;
  // }
  .eventType-accordion {
    box-shadow: none;
    margin-top: 8px !important;
    .eventType-header {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      color: var(--accordian-headers-color) !important;
      padding: 6px 16.2px 9px 22px;
      height: 26px !important;
      display: flex;
      align-items: center;
      overflow: hidden;
      font-weight: 600;
      .invert {
        filter: invert(1);
      }
      .sport-inplay-icon {
        height: 24px;
        width: 24px;
        margin-right: 12px;
      }
      .MuiAccordionSummary-expandIcon {
        opacity: 1;
        .expand-icon {
          color: var(--accordian-headers-color) !important;
        }
      }
    }
    .inplay-events-tbl-container,
    .events-table-content {
      padding: 0px;
      .all-markets-nav-link {
        cursor: pointer !important;
        color: var(--text-primary) !important;
        .odds-web-view {
          .teams .team-name {
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: var(--text-primary) !important;
            font-style: normal;
            font-weight: var(--font-weight);
            font-size: 13px;
            line-height: 17.5px;
            padding-left: 5px !important;
          }
        }
      }
    }
  }

  .table-ctn,
  .tbl-ctn {
    .MuiPaper-root {
      margin-top: 0;
      border-radius: 0;
      box-shadow: none !important;
    }

    .MuiTableContainer-root {
      background: transparent;
      // border-radius: 8px;
      .MuiTable-root {
        .MuiTableHead-root {
          border: none;
          .MuiTableRow-head {
            background-color: var(--market-table-row);
            .MuiTableCell-head {
              white-space: nowrap;
              font-weight: bold;
              font-size: 14px;
              color: var(--text-theme);
              font-family: var(--font-family) !important;
              text-transform: uppercase;
              .team-cell-mob {
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                  margin-right: 5px;
                }
              }
            }
          }
        }

        .MuiTableBody-root {
          border: none;

          .MuiTableRow-root {
            border-bottom: 1px solid rgba(53, 48, 49, 0.1);
            .all-markets-nav-link {
              font-weight: 800;
              text-decoration: none;
              width: 180px !important;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              @media (max-width: 900px) {
                width: 100% !important;
              }
              .mob-view {
                display: flex !important;
                .odds-block {
                  @media (max-width: 900px) {
                    display: inline-flex;
                    width: 100%;
                    align-items: center;
                    border-radius: 0px;
                    padding: 0px;
                    margin-top: 0px;
                    padding: 0px;
                  }
                  .mob-exch-odd-view {
                    @media (max-width: 900px) {
                      width: 100%;
                    }
                  }
                }
                @media (min-width: 900px) {
                  display: none !important;
                }
              }
            }

            .schedule-cell {
              white-space: break-spaces;
              display: flex;
              align-items: center;
              padding: 14px !important;
              font-family: var(--font-family);
              width: 110px;
            }
            .odds-cell-suspended {
              min-height: 36px;
              padding: 2px 2px;
              min-width: 120px;
              pointer-events: none;
              cursor: default;
              background: var(--side-menu);
              border: 1px solid #000;
              // width: 90%;
              .suspended-text {
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--text1);
                font-weight: bold;
                font-size: 20px;
                text-transform: uppercase;
                font-family: var(--font-family);
              }
            }
            .suspended-text-row {
              min-height: 36px;
              padding: 2px 2px;
              pointer-events: none;
              cursor: default;
              border: 1px solid #000;
              background: var(--side-menu);
              width: 100%;
              .suspended-text {
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--text1);
                font-weight: bold;
                font-size: 22px;
                text-transform: uppercase;
                font-family: var(--font-family);
              }
            }

            .schedule-cell1 {
              white-space: break-spaces;
              display: flex;
              justify-content: space-around;
              align-items: center;
              width: 110px;
              padding-top: 6px !important;
              font-family: var(--font-family);
              @media screen and (max-width: 900px) {
                display: none !important;
              }
              .inply {
                color: var(--text-primary) !important;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
              }
              .date {
                font-weight: 400px;
                font-size: 12px !important;
              }
              img {
                margin-right: 5px;
                margin-left: 5px;
              }
            }
          }

          // .MuiTableRow-root:hover {
          //   background-color: rgba(255, 167, 36, 0.2) !important;
          // }

          .MuiTableCell-root {
            padding: 8px;

            .odds-block {
              display: inline-flex;
              width: 100%;
              justify-content: center;
              align-items: center;
              .back-odd,
              .lay-odd {
                cursor: default !important;
                width: 63px;
                height: 38px;
                padding: 2px 4px;
                font-style: normal;
                border-radius: 50px;
                align-items: center;
                display: flex;
                justify-content: center;
                align-items: center !important;
                .price {
                  color: var(--text-common-dark);
                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 13px;
                  line-height: 15px;
                }
                .size {
                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 10px;
                  line-height: 12px;
                  text-align: center;

                  color: var(--text-common-dark);
                }
              }
            }
          }
        }
      }
    }

    .live-img {
      width: 34px;
      height: 18px;
    }

    .all-markets-nav-link {
      text-decoration: none;
      line-height: 34px;
      color: var(--text-primary) !important;
      .teams .team-name {
        max-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--text-primary) !important;
        font-style: normal;
        font-weight: var(--font-weight);
        font-size: 13px;
        line-height: 17.5px;
        padding-left: 5px !important;
      }
    }
  }
}

.back-odd {
  background: var(--back-odd-background);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.lay-odd {
  background: var(--lay-odd-background);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 1200px) {
  .events-table-ctn {
    display: flex;
    .events-section {
      width: 78%;
    }
    .sport-info-section {
      width: 20%;
      margin-left: auto;
    }
  }
  .inplay-events-table-ctn {
    flex-direction: column;
  }
}

@media (max-width: 1200px) {
  .events-table-ctn
    .events-table-content
    .MuiPaper-root
    .events-table
    .MuiTableRow-root
    .odds-cell {
    display: none;
    min-width: 120px;
  }

  .events-table-ctn
    .events-table-content
    .MuiPaper-root
    .events-table
    .MuiTableRow-root
    .odds-cell-head,
  .events-table-ctn
    .events-table-content
    .MuiPaper-root
    .events-table
    .MuiTableRow-root {
    .schedule-cell {
      display: none;
    }
    .MuiTableCell-root {
      .odds-block {
        .back-odd,
        .lay-odd {
          min-height: 26px;
        }
      }
    }
  }

  .odds-row {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 6px;
    .odds-block {
      display: flex;
      flex: 1 1 33%;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin: 0px;
      padding: 0px;
      font-size: 12px;
    }
  }
}

@media (max-width: 1120px) {
  .events-table-ctn {
    .banner-container {
      display: none;
    }
    .eventType-accordion {
      .eventType-header {
        font-size: 16px;
        line-height: 18px;
        padding: 7px 14px 7px 7px;
        height: 36px !important;
        border-radius: 0px !important;
        .sport-inplay-icon {
          height: 17px;
          width: 17px;
          margin-right: 8px;
        }
      }
      .sport-inplay-icon {
        height: 24px;
        width: 24px;
        margin-right: 12px;
      }
    }
    .events-table-content {
      .MuiPaper-root {
        margin-top: 0px;
        .events-table {
          // margin-top: 10px;
          border-radius: 0px;
          overflow: hidden;
          .MuiTableRow-root {
            .schedule-cell {
              display: none;
            }
            .odds-cell-head {
              display: none;
            }
            .teams-cell {
              .web-view {
                display: none;
              }
              .live-img {
                width: 40px;
                margin-left: 10px;
              }
              min-width: 100% !important;
              max-width: 100% !important;
              .all-markets-nav-link {
                text-decoration: none;
                color: var(--text-primary) !important;
                .team-name {
                  white-space: nowrap;
                  color: var(--text-primary) !important;
                  font-style: normal;
                  font-weight: var(--font-weight);
                  font-size: 12px;
                  line-height: 17.5px;
                  padding-left: 5px !important;
                }

                .event-info {
                  width: 100%;
                  padding: 0px;
                  .event-name {
                    text-decoration: none;
                    color: var(--text-primary) !important;
                    font-size: 14px;
                    font-weight: 400;
                    display: flex;
                    @media screen and (max-width: 900px) {
                      font-size: 13px;
                      line-height: 15px;
                      font-weight: bold;
                    }
                  }
                  .schedule-time {
                    text-decoration: none;
                    color: var(--text-primary) !important;
                    font-size: 12px;
                    font-weight: 400;
                    display: flex;
                    flex-wrap: wrap;
                    line-height: 17px;
                    @media screen and (max-width: 900px) {
                      font-size: 10px;
                    }
                  }
                }
              }
            }
            .score-cell {
              display: none;
              max-width: 40px !important;
              .score {
                max-width: 100%;
                font-size: 10px;
                line-height: 14px;
              }
            }

            .odds-cell {
              display: none;
              min-width: 120px;

              .odds-block {
                .back-odd,
                .lay-odd {
                  min-width: 40px;
                  max-width: 50px;
                  width: 48%;
                  min-height: 36px;
                  padding: 2px 2px;
                  display: flex;
                  align-items: center !important;
                  .price {
                    font-size: 12px;
                    line-height: 15px;
                  }
                  .size {
                    font-size: 10px;
                    line-height: 12px;
                  }
                  &.disabled {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .lock-icon {
                      height: 16px;
                      width: 18px;
                    }
                  }
                }
              }
            }
            .all-markets-link-cell {
              min-width: 20px;
              padding-left: 0px !important;
              ion-img {
                height: 12px;
                width: 7px;
                margin-right: 0px;
                margin-left: auto;
              }
            }
          }
          .MuiTableRow-root {
            border: 0px;
          }
          .MuiTableHead-root .MuiTableRow-root {
            .MuiTableCell-root {
              padding: 8px;
              font-size: 14px;
              line-height: 15px;
            }
          }
          .MuiTableBody-root .MuiTableRow-root {
            .MuiTableCell-root {
              padding: 6px;
              padding-right: 0px;
            }
          }
        }
      }
    }

    .bs-accordion {
      // bottom: 50px;
      // width: 100%;
      // right: 0px;
      display: none;
    }
    .footer-row {
      margin-top: 10px;
      .pagination-btn {
        font-size: 11px;
        height: 24px;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .events-table-ctn,
  .inplay-events-table-ctn {
    width: 100%;
    .table-ctn,
    .tbl-ctn {
      .MuiTableContainer-root {
        .MuiTable-root {
          .MuiTableBody-root {
            .MuiTableCell-root {
              font-size: 14px;
              padding: 7px 8px;
              .teams {
                border: 0px;
                font-family: var(--font-family);
                // font-size: 14px !important;
                font-weight: 600;
                cursor: pointer;
                .live-img {
                  margin-left: 1%;
                  object-fit: contain;
                  // width: 15px;
                  // height: 15px;
                  width: 34px;
                  height: 14px;
                }
              }
              .event-date {
                font-size: 12px;
                border: 0px;
                font-family: var(--font-family);
                font-weight: 400;
              }
              .odds-block {
                display: inline-flex;
                width: 100%;
                align-items: center;
                border-radius: 0px;
                padding: 0px;
                margin-top: 0px;
                padding: 0px;
                .back-odd,
                .lay-odd {
                  // width: 46px;
                  // border-radius: 50%;
                  // height: 41px;
                  width: 57px;
                  max-width: 60px;
                  height: 31px;
                  border-radius: 38px;
                  display: flex;
                  align-items: center;
                  .price {
                    font-size: 10px;
                    line-height: 24px;
                    align-items: center;
                  }
                }
                .mob-exch-odd-view {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    .sport-info-section {
      display: none;
    }
  }
}
.inplay-table {
  .MuiTableHead-root {
    &.web-view {
      @media (max-width: 900px) {
        display: none !important;
      }
    }
  }
  .inply-row {
    &.mob-view {
      display: flex !important;
      justify-content: center !important;
      @media (min-width: 900px) {
        display: none !important;
      }
    }
  }
}
