.change-pwd-form-ctn {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .text-black {
    color: var(--ion-text-white);
  }
  .pwd-input .MuiOutlinedInput-input {
    background-color: var(--disabled-bg) !important;
  }

  .usr-input,
  .pwd-input {
    display: inline-grid;
    margin-top: 30px;
    width: 100%;

    .login-input-field,
    .MuiOutlinedInput-input {
      border-radius: 0px !important;
      background-color: var(--disabled-bg) !important;
    }
  }
  .infoicon {
    color: var(--ion-text-white);
    vertical-align: sub;
  }

  .submit-form-btn {
    // margin-top: 20px !important;
    margin-bottom: 20px;
    width: 100%;
    height: 48px;

    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    .MuiButton-label {
      color: var(--ion-text-white);
    }
  }

  .accept-terms-input {
    padding-top: 14px;
    width: 100%;
    .MuiFormControlLabel-root {
      margin-left: 0px;
      margin-right: 0px;
      align-items: flex-start;
      .MuiFormControlLabel-label {
        margin-left: 10px;
        color: var(--ion-text-white);
      }
      .MuiOutlinedInput-input {
        background-color: var(--disabled-bg) !important;
      }
    }

    .MuiFormHelperText-root {
      color: var(--text1);
    }

    .accept-terms-checkbox {
      color: var(--ion-color-primary);
      position: relative !important;
    }
  }
}

.error-msg {
  color: var(--ion-color-danger);
  margin-top: 20px;
  text-align: center;
}
.success-msg {
  color: var(--ion-color-success);
  margin-top: 20px;
  text-align: center;
}
.user-name,
.pwd-field {
  width: 100%;
  height: 48px;
}

.input-tooltip {
  color: var(--ion-text-color);
  font-size: 20px;
  padding-left: 4px;
}

// @media (min-width: 481px) and (max-width: 767px) {
//   .user-name,
//   .pwd-field,
//   .submit-form-btn {
//     max-width: 298px;
//   }
// }

// @media (min-width: 320px) and (max-width: 480px) {
//   .user-name,
//   .pwd-field,
//   .submit-form-btn {
//     max-width: 250px;
//   }
// }
.change-pwd-form-ctn {
  .MuiOutlinedInput-input {
    background-color: var(--disabled-bg) !important;
    border-radius: 0px !important;
    border: 1px solid var(--filter-border);
  }
}
.change-pwd-form-ctn .pwd-input .MuiOutlinedInput-input {
  background-color: var(--disabled-bg) !important;
}
