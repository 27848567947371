$assetPath: '../../assets/images/brand';
ion-content.background {
  --background: url(#{$assetPath}/loginbg.png) 0 0/100% 100% no-repeat;
  @media screen and (max-width: 720px) {
    --background: url(#{$assetPath}/loginbgmob.png) 0 0/100% 100% no-repeat;
    max-height: 100vh !important;
    overflow-y: hidden;
  }
}
.login-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  display: flex;
  border-radius: 4px;
  height: 600px;
  width: 55%;
  @media screen and (min-width: 1024px) and (min-height: 1320px) {
    width: 65%;
  }
  @media screen and (min-width: 1024px) and (max-height: 1310px) {
    width: 65%;
  }
  background: var(--modal-bg) !important;
  .card-left-side {
    width: 48.5%;
    display: flex;
    align-items: center;
    @media screen and (max-width: 720px) {
      width: 50%;
    }
    // justify-content: center;
    .card-img {
      width: 50%;
      height: 50%;
      margin-top: 20px;
      z-index: 1;
      bottom: 0 !important;
      position: absolute;
      height: var(--login-card-height);
    }
    .go-corner-bottom {
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100px;
      height: 100px;
      overflow: hidden;
      bottom: 0%;
      /* left: 35%; */
      color: var(--table-head-text) !important;
      background: var(--active-tab) !important;
      border-radius: 0px 112px 3px 0px;
      cursor: pointer;
      .go-arrow {
        margin-top: -4px;
        margin-right: -4px;
        color: var(--ion-text-white);
      }
    }
    .go-corner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 114px;
      height: 100px;
      overflow: hidden;
      top: 0;
      left: 35%;
      color: var(--table-head-text) !important;
      background: var(--active-tab) !important;
      border-radius: 3px 6px 3px 116px;
      cursor: pointer;
      .go-arrow {
        margin-top: -4px;
        margin-right: -4px;
        color: var(--ion-text-white);
      }
    }
  }
  .card-right-side {
    width: 50%;
    justify-content: center;
    display: inline;
    padding-left: 1%;
    padding-right: 1%;
    .MuiInputBase-root {
      border: 1px solid #5f5f5f !important;
      background: var(--login-input-bg) !important;
      width: 100%;
    }
  }
  .MuiFormControl-root {
    color: var(--text-white) !important;
    background-color: var(--login-input-bg) !important;
    border-radius: 8px;
    width: 100%;
    border: 0px;
    border-radius: 8px;
  }
}

.mobile-view-login {
  display: flex;
  justify-content: center !important;
  width: 100%;
  .login-card-mob {
    background: var(--modal-bg) !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center !important;
    border-radius: 4px;
    height: 40vh;
    width: 100vw;

    .MuiInputBase-root {
      border: 1px solid #5f5f5f !important;
      background: var(--login-input-bg) !important;
    }

    .go-corner-bottom {
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 80px;
      height: 80px;
      overflow: hidden;
      bottom: 0%;
      color: var(--table-head-text) !important;
      background: var(--active-tab) !important;
      border-radius: 0px 112px 3px 0px;
      cursor: pointer;
      .cross {
        font-size: 45px !important;
      }
      .go-arrow {
        margin-right: -2px;
        color: var(--ion-text-white);
        svg,
        .cross {
          font-size: 45px !important;
        }
      }
    }
    .card-img {
      // width: 50%;
      // height: 50%;
      z-index: 1;
      bottom: -10px;
      position: absolute;
    }
    .go-corner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 80px;
      height: 80px;
      overflow: hidden;
      top: 0;
      right: 0;
      color: var(--table-head-text) !important;
      background: var(--active-tab) !important;
      border-radius: 3px 6px 3px 116px;
      cursor: pointer;
      .go-arrow {
        margin-top: -10px;
        margin-right: -10px;
        color: var(--ion-text-white);
        svg,
        .cross {
          font-size: 45px !important;
          z-index: 1;
        }
      }
    }
  }
  .login-ctn-mob {
    // top: 20%;
    // bottom: 50%;
    margin-top: 40vh;
  }
}
