.main-header-ctn {
  width: 100%;
  padding: 0 18px;
  background: var(--ion-header-background) !important;
  height: auto;
  margin-top: 0% !important;
  height: 60px;
  span,
  div {
    color: var(--tab-text) !important;
    font-weight: 600;
  }
  .branding-ctn {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 2px !important;

    .site-title {
      .site-logo {
        width: var(--logo-xl-width);
        max-height: var(--logo-xl-height);
        left: 23px;
        margin-top: 2px !important;
      }
    }
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  .h-btns {
    font-size: 18px;
    font-weight: 600;
    color: var(--tab-text);
    background: var(--btn-bg);
    letter-spacing: 0.02em;
  }

  .live-clock {
    color: var(--tab-text);
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 18px;
    .date {
      opacity: 1;
    }
  }

  .header-actions {
    display: flex;

    font-family: var(--font-family);
    font-size: 12px;
    .header-right-actions {
      display: flex;
      align-items: center;
      font-family: var(--font-family);
      font-size: 13px;
      cursor: pointer;
      .profile-section {
        display: flex;
        text-transform: capitalize;
        .btn-header {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      .h-list-item {
        min-width: 100px;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .main-header-ctn {
    padding: 0px;
    .site-title {
      padding: 0 0 0 10px;
      .site-logo {
        width: var(--logo-xl-width);
        max-height: var(--logo-xl-height);
        left: 23px;
        top: 2px;
      }
    }

    .h-btns {
      padding: 2px 20px;
    }

    .live-clock {
      display: none;
    }

    .header-actions {
      width: 68%;
      height: 100%;
      justify-content: flex-end;

      .header-right-actions {
        display: flex;
        flex-direction: row;
        text-transform: capitalize;
        .profile-section {
          display: flex;
          flex-direction: row;
          padding: 0px 10px;
          height: 100%;
          text-transform: capitalize;
          .user-icon {
            .MuiListItemAvatar-root {
              margin-top: 0.3rem;
            }
          }
        }
        .h-list-item {
          min-width: 65px;
          font-size: 10px;
          font-weight: 600;
          margin-top: auto;
          margin-bottom: auto;
          text-transform: capitalize;
          .btn-content {
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }
  }
}
.input-wrapper {
  font-family: var(--font-family);
  font-size: 12px;
}
