body>* {
    scrollbar-color: var(--btn-bg) white; /* thumb and track color */
    scrollbar-width: thin;
}
*>::-webkit-scrollbar {
    width: 0.6em !important;
    height: 0.3em !important;
}

*>::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
}

*>::-webkit-scrollbar-thumb {
    background:var(--btn-bg) !important; 
    border-radius: 20px !important;
}