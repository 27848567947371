// IonSelect
ion-select {
  height: 30px;
  padding: 15px 16px 14px 16px;
  box-sizing: border-box;
  background: var(--select-element-background);
  color: white;
}

ion-select-popover {
  background: var(--select-element-background);
  ion-list {
    padding: 0px !important;
    font-weight: 500;
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    background: var(--select-popover-background) !important;
    ion-item {
      color: var(--ion-text-white);
      --inner-border-width: 0 0 0 0 !important;
      font-size: 12px;
      font-family: var(--font-family);
      &:not(:last-child) {
        border-bottom: 1px solid rgba(var(--ion-text-color-contrast-rgb), 0.12);
      }
      ion-radio {
        opacity: 0;
      }
      &:hover {
        color: var(--ion-text-white);
        background: var(--active-tab);
        // border-left: 4px solid var(--btn-bg);
      }
      &.item-radio-checked {
        color: var(--ion-text-white);
        border-left: 4px solid var(--btn-bg);
        background: var(--select-popover-active-background);
      }
    }
  }
}

// MaterialUI Select
.MuiSelect-root {
  height: 48px;
  box-sizing: border-box;
  border-radius: 6px;
  background: none !important;
}
