.modal-close-btn {
  position: absolute !important;
  top: 8px;
  right: 8px;
  color: #9e9e9e;

  .MuiIconButton-label {
    svg {
      fill: var(--ion-text-light);
    }
  }
}

.dark-colose-btn {
  color: var(--dialog-header-background) !important;
}

.dark-colose-btn .MuiIconButton-root {
  color: var(--dialog-header-background) !important;
}

.MuiDialog-paper {
  .MuiDialogContent-root {
    .modal-body {
      padding: 14px !important;
    }
  }

  .MuiDialogActions-root {
    background: var(--btn-bg);
    justify-content: center;
  }
}
.dialog-login {
  .modal-close-btn {
    position: absolute !important;
    top: 8px;
    right: 8px;
    color: #9e9e9e;

    .MuiIconButton-label {
      svg {
        display: none;
        fill: var(--ion-text-light);
      }
    }
  }
  background: none !important;
  .dialog-div-body {
    background: var(--modal-bg);
    color: var(--ion-text-white);
    overflow: hidden;
    height: 350px;
    .go-corner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 38px;
      height: 38px;
      overflow: hidden;
      top: 0;
      right: 0;
      color: var(--table-head-text) !important;
      background: var(--active-tab) !important;
      border-radius: 0 4px 0 32px;
      cursor: pointer;
      .go-arrow {
        margin-top: -4px;
        margin-right: -4px;
        color: var(--ion-text-white);
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .costom-dialog,
  .dialog-login {
    padding: 0px !important;
    margin: 0px;
    .dialog-div-body {
      min-height: 100% !important;
      max-height: 100% !important;
      background: var(--sub-header-color);
      color: var(--ion-text-white);
      display: block;
      justify-content: center;
      align-items: center;
    }
  }
}
.MuiDialog-root .MuiDialog-container .MuiDialog-paper .MuiDialogContent-root {
  @media screen and (max-width: 720px) {
    height: 70vh;
    color: var(--ion-text-white);
  }
}
.costom-dialog {
  .modal-close-btn {
    position: absolute !important;
    top: 8px;
    right: 8px;
    color: #9e9e9e;
    .MuiIconButton-label {
      svg {
        display: none;
        fill: var(--ion-text-light);
      }
    }
  }
  background: none !important;
  .dialog-div-body {
    background: var(--sub-header-color);
    color: var(--ion-text-white);
    overflow: hidden;
    .go-corner {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 38px;
      height: 38px;
      overflow: hidden;
      top: 0;
      right: 0;
      color: var(--table-head-text) !important;
      background: var(--active-tab) !important;
      border-radius: 0 4px 0 32px;
      cursor: pointer;
      .go-arrow {
        margin-top: -4px;
        margin-right: -4px;
        color: var(--ion-text-white);
      }
    }
  }
}
