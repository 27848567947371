.MuiPopover-root {
  font-size: 12px;
  font-family: var(--font-family) !important;
  .MuiPopover-paper {
    background: var(--ion-popover-background);

    .MuiList-root,
    .MuiMenu-list {
      padding: 0px;
      .MuiListItem-root,
      .MuiMenuItem-root {
        color: var(--ion-text-white);
        font-family: var(--font-family) !important;
        font-size: 12px;
        height: 48px;
        padding-left: 16px;
        &:not(:last-child) {
          border-bottom: 1px solid
            rgba(var(--ion-text-white-contrast-rgb), 0.12);
        }
        &:hover {
          background: var(--ion-popover--hover-background);
        }
        &.Mui-selected {
          color: var(--ion-text-white-contrast);
          background: var(--ion-popover--hover-background);
          border-left: 4px solid var(--btn-bg);
        }
      }
    }
  }
}
.item.sc-ion-label-md-h, .item .sc-ion-label-md-h {
  font-family: var(--font-family) !important;
}